// Custom version of https://raw.githubusercontent.com/Grible/timetable.js/master/app/scripts/plugin.js
/*jshint -W079*/

"use strict";
var Timetable = function() {
    this.scope = {
        hourStart: 9,
        hourEnd: 17
    },
    this.locations = [],
    this.events = []
};
Timetable.Renderer = function(e) {
    if (!(e instanceof Timetable))
        throw new Error("Initialize renderer using a Timetable");
    this.timetable = e
},
function() {
    function e(e, n) {
        return t(e) && t(n)
    }
    function t(e) {
        return n(e) && r(e)
    }
    function n(e) {
        return e === parseInt(e, 10)
    }
    function r(e) {
        return e >= 0 && 24 > e
    }
    function o(e, t) {
        return -1 !== t.indexOf(e)
    }
    function a(e, t) {
        var n = e instanceof Date && t instanceof Date,
            r = t > e;
        return n && r
    }
    function i(e) {
        for (; e.firstChild;)
            e.removeChild(e.firstChild)
    }
    function c(e) {
        var t = 10 > e ? "0" : "";
        return t + e + ":00"
    }
    Timetable.prototype = {
        setScope: function(t, n) {
            if (!e(t, n))
                throw new RangeError("Timetable scope should consist of (start, end) in whole hours from 0 to 23");
            return this.scope.hourStart = t, this.scope.hourEnd = n, this
        },
        addLocations: function(e) {
            function t() {
                return e instanceof Array
            }
            var n = this.locations;
            if (!t())
                throw new Error("Tried to add locations in wrong format");
            return e.forEach(function(e) {
                if (o(e, n))
                    throw new Error("Location already exists");
                n.push(e)
            }), this
        },
        addEvent: function(e, t, n, r, i) {
            if (!o(t, this.locations))
                throw new Error("Unknown location");
            if (!a(n, r))
                throw new Error("Invalid time range: " + JSON.stringify([n, r]));
            return this.events.push({
                name: e,
                location: t,
                startDate: n,
                endDate: r,
                // url: i
                project_event: i
            }), this
        }
    },
    Timetable.Renderer.prototype = {
        draw: function(e) {
            function t(e, t) {
                return t > e ? t - e : 24 + t - e
            }
            function n(e) {
                if (null === e)
                    throw new Error("Timetable container not found")
            }
            function r(e) {
                var t = e.appendChild(document.createElement("aside")),
                    n = t.appendChild(document.createElement("ul"));
                o(n)
            }
            function o(e) {
                for (var t = 0; t < p.locations.length; t++) {
                    var n = e.appendChild(document.createElement("li")),
                        r = n.appendChild(document.createElement("span"));
                    r.className = "row-heading",
                    r.textContent = p.locations[t]
                }
            }
            function a(e) {
                var t = e.appendChild(document.createElement("section")),
                    n = t.appendChild(document.createElement("time"));
                u(n),
                l(n)
            }
            function u(e) {
                for (var t = e.appendChild(document.createElement("header")), n = t.appendChild(document.createElement("ul")), r = !1, o = !1, a = p.scope.hourStart; !r;) {
                    var i = n.appendChild(document.createElement("li")),
                        u = i.appendChild(document.createElement("span"));
                    u.className = "time-label",
                    u.textContent = c(a),
                    a !== p.scope.hourEnd || p.scope.hourStart === p.scope.hourEnd && !o || (r = !0),
                    24 === ++a && (a = 0, o = !0)
                }
            }
            function l(e) {
                var t = e.appendChild(document.createElement("ul"));
                t.className = "room-timeline";
                for (var n = 0; n < p.locations.length; n++) {
                    var r = t.appendChild(document.createElement("li"));
                    s(p.locations[n], r)
                }
            }
            function s(e, t) {
                for (var n = 0; n < p.events.length; n++) {
                    var r = p.events[n];
                    r.location === e && d(r, t)
                }
            }
            function d(e, t) {
                var n = e.project_event.path,
                    r = n ? "a" : "span",
                    o = t.appendChild(document.createElement(r));//,
                    // a = o.appendChild(document.createElement("small"));

                o.title = e.name,
                n && (o.href = e.project_event.path),
                o.className = "time-entry",
                o.style.width = h(e),
                o.style.left = f(e);//,

                if (e.project_event.teaser) {
                  var teaser = o.appendChild(document.createElement("div"));
                  teaser.className = "teaser";

                  var img = teaser.appendChild(document.createElement('img'));
                  img.src = e.project_event.teaser;
                }

                var content = o.appendChild(document.createElement("div"));
                content.className = "content";

                var time = content.appendChild(document.createElement("div"));
                time.className = "human-time";
                time.textContent = e.project_event.human_starts_at + ' - ' + e.project_event.human_ends_at;

                var name = content.appendChild(document.createElement("div"));
                name.className = "name";
                name.textContent = e.project_event.name;

                var users = content.appendChild(document.createElement("div"));
                users.className = "users";

                $(e.project_event.users).each(function(a,b) {
                  var user = users.appendChild(document.createElement("div"));
                  user.className = "user";

                  if (this.profile_picture) {
                    var img = user.appendChild(document.createElement('img'));
                    img.src = this.profile_picture;
                  }

                  var name = user.appendChild(document.createElement("div"));
                  name.className = "name";
                  name.textContent = this.name;
                });

                var icon_container = o.appendChild(document.createElement("div"));
                icon_container.className = "icon";

                var icon = icon_container.appendChild(document.createElement("i"));
                icon.className = "fa fa-arrow-right";
            }
            function h(e) {
                var t = e.startDate,
                    n = e.endDate,
                    r = m(t, n);
                return r / E * 100 + "%"
            }
            function m(e, t) {
                return (t.getTime() - e.getTime()) / 1e3 / 60 / 60
            }
            function f(e) {
                var t = e.startDate,
                    n = t.getHours() + t.getMinutes() / 60;
                return (n - p.scope.hourStart) / E * 100 + "%"
            }
            var p = this.timetable,
                E = t(p.scope.hourStart, p.scope.hourEnd),
                v = e;//document.querySelector(e);
            n(v),
            i(v),
            r(v),
            a(v)
        }
    }
}();


$(document).on("turbo:load data-remote-target-refresh", function () {
  $(".hku-timetable").each(function () {
    var data = $(this).data("timetable");
    var date = new Date(Date.parse($(this).data("date")));
    var day =
      date.getDate() + 100 * (date.getMonth() + 1) + date.getFullYear() * 10000;

    var timetable = new Timetable();

    var first_start = 23;
    var last_end = 1;
    var locations = [];

    $.each(data, function (location) {
      locations[locations.length] = location;
    });

    timetable.addLocations(locations);

    $.each(data, function (location, _) {
      $.each(this, function (_) {
        var project_event = this;
        var starts_at = new Date(Date.parse(project_event.starts_at));
        var ends_at = new Date(Date.parse(project_event.ends_at));

        var starts_at_day =
          starts_at.getDate() +
          100 * (starts_at.getMonth() + 1) +
          starts_at.getFullYear() * 10000;
        var ends_at_day =
          ends_at.getDate() +
          100 * (ends_at.getMonth() + 1) +
          ends_at.getFullYear() * 10000;

        if (starts_at_day < day) {
          starts_at = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0
          );
        }

        if (ends_at_day > day) {
          ends_at = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            23,
            59,
            59
          );
        }

        if (starts_at.getHours() < first_start) {
          first_start = Math.max(0, starts_at.getHours() - 1);
        }

        if (ends_at.getHours() > last_end) {
          last_end = Math.min(24, ends_at.getHours() + 1);
        }

        timetable.addEvent(
          project_event.name,
          location,
          starts_at,
          ends_at,
          project_event
        );
      });
    });

    if (last_end == 24) {
      last_end = 0;
    }

    timetable.setScope(first_start, last_end);
    var renderer = new Timetable.Renderer(timetable);
    renderer.draw(this);
  });
});
